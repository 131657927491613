const category = [
  {
    id: "158",
    title: "投资入门",
    children: [
      {
        id: 161,
        title: "开户",
      },
      {
        id: 162,
        title: "出入金",
      },
      {
        id: 163,
        title: "交易",
      },
      {
        id: 164,
        title: "名词解释",
      },
    ],
  },
  {
    id: "159",
    title: "投资进阶",
    children: [
      {
        id: 165,
        title: "交易指导",
      },
      {
        id: 166,
        title: "盈利计算",
      },
      {
        id: 167,
        title: "产品介绍",
      },
      {
        id: 172,
        title: "专家评论",
      },
    ],
  },
  {
    id: "160",
    title: "投资高手",
    children: [
      {
        id: 168,
        title: "技巧学习",
      },
      {
        id: 169,
        title: "专家观点",
      },
      {
        id: 170,
        title: "教学专题",
      },
      {
        id: 171,
        title: "推荐有奖",
      },
    ],
  },
  {
    id: "173",
    title: "技术指标",
    children: [
      {
        id: 174,
        title: "指标分析",
      },
    ],
  },
  {
    id: "151",
    title: "帮助中心",
    children: [
      {
        title: "虚拟币",
        id: 152,
      },
      {
        title: "账户相关",
        id: 153,
      },
      {
        title: "交易相关",
        id: 154,
      },
      {
        title: "充值与取款",
        id: 155,
      },
    ],
  },
  {
    id: "156",
    title: "公告",
    children: [
      {
        title: "官网公告",
        id: "156",
      },
    ],
  },
];

const limitNews = 5;

let linkConfig = {
  mis: "",
  msite: "",
  h5transaction: "https://www.btios888.com/",
  online:
    "https://btcccfd.kf5.com/kchat/38726?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0",
  strategy: "473",
  download: {
    ios: {
      btcc: "https://apps.apple.com/cn/app/id6465077365",
      new_btcc: [
        "itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download.plist",
        "itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download2.plist",
      ],
      jn:
        "https://downloads.zhongshunagriculture.cn/source/material/download_jn.plist",
      new_jn: [
        "itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download_jn.plist",
        "itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download_jn1.plist",
      ],
      jn_appstore: "https://itunes.apple.com/cn/app/id1457660095?mt=8",
      btcchy: "https://itunes.apple.com/hk/app/id1462880009?mt=8",
      btccgl:
        "https://apps.apple.com/hk/app/%E5%BF%85%E6%8B%93%E7%8E%AF%E7%90%83cfd/id1468237024",
      zrjy: "",
      xhjy: "",
      nhjy:
        "https://apps.apple.com/hk/app/%E5%8D%97%E5%8D%8E%E9%87%91%E4%B8%9A/id6477434010",
      rsjy: "",
      hkjy:
        "https://apps.apple.com/cn/app/%E6%B1%87%E5%87%AF%E9%87%91%E4%B8%9Apro/id6504783901",
      ljajy: ""
    },
    android:
      "https://appcfg.uitrade.net/api/app/version/queryVersion?company_id=1&app_id=10",
    androidUrl: {
      btcc: [
        "https://downloads.btgwxz8.com/source/material/btglobal.apk",
        "https://mp-074d31c0-761b-4193-8c5c-a4b247ef9811.cdn.bspapp.com/主包/btglobal.apk",
        "https://mp-074d31c0-761b-4193-8c5c-a4b247ef9811.cdn.bspapp.com/主包/btglobal1.apk",
      ],
      btcc_backup:
        "https://mp-074d31c0-761b-4193-8c5c-a4b247ef9811.cdn.bspapp.com/主包/btglobal1.apk",
      jn:
        "https://downloads.zhongshunagriculture.cn/source/material/jncf_gw.apk",
      btcchy:
        "https://downloads.zhongshunagriculture.cn/source/material/btcchy.apk",
      btccgl: "https://downloads.btgwxz8.com/source/material/btglobal.apk",
      zrjy: "https://120.79.254.1:6010/source/material/ZRJY_GW.apk",
      xhjy: "https://39.108.127.136:6010/source/material/XHJY_GW.apk",
      nhjy: "https://39.108.127.136:6010/source/material/NHJY_GW.apk",
      rsjy: "https://downloads.btgwxz8.com/source/material/RSHJ_GW.apk",
      hkjy: "https://downloads.btgwxz8.com/source/material/HKJY_GW.apk",
      ljajy: "https://downloads.btgwxz8.com/source/material/LJAJY_GW.apk"
    },
  },
};

if (process.env.BUILD_ENV === "uat") {
  linkConfig.strategy = "259";
  linkConfig.download.ios.btcc =
    "https://downloads.zhongshunagriculture.cn/source/material/download.plist";
  linkConfig.download.android =
    "https://appcfg.gtsuat.com/api/app/version/queryVersion?company_id=1&app_id=10";
}
const webCfg = {
  btcc: {
    copyright: "BT Global",
  },
  jn: {
    copyright: "金牛财富",
  },
  btcchy: {
    copyright: "BTCC",
  },
  btccgl: {
    copyright: "BT Global",
  },
  zrjy: {
    copyright: "中融金业",
  },
  xhjy: {
    copyright: "星河金业",
  },
  nhjy: {
    copyright: "南华金业",
  },
  rsjy: {
    copyright: "荣晟黄金",
  },
  hkjy: {
    copyright: "汇凯金业",
  },
  ljajy: {
    copyright: "利家安金业",
  },
};

export { limitNews, linkConfig, category, webCfg };
