import React from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "antd-mobile";
import { BaseLayout, Loading } from "components";
import { getNews, getCategory } from "services";
import to from "utils/to";
import "react-perfect-scrollbar/dist/css/styles.css";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from 'utils';

const env = process.env.BUILD_ENV || '';

class GuideList extends React.PureComponent {
  state = {
    askList: [],
    loading: true,
    askListData: [],
    count: 5,
    extendId: 0, // 当前展开的分类id
    extendIdsMap: new Map() // 展开的分类
  };
  componentDidMount() {
    document.title = "新手指南";
    const id = env === "uat" ? 260 : 717; //  常见问题
    this.getList(id);
  }

  // 获取列表
  getList = async id => {
    const [err, res] = await to(getCategory(id, { limit: 10 }));

    if (err) {
      console.log(err);
      return;
    }

    if (res.success && Array.isArray(res.ch_msg)) {
      this.setState({
        loading: false,
        askList: [...res.ch_msg],
        askListData: [...res.ch_msg] // 默认显示前5条
      });
    }
  };

  // 展开子分类
  onExtend = async (id) => {
    if(this.state.extendId === id) {
      this.setState({
        extendId: 0
      })
    } else {
      if(this.state.extendIdsMap.get(id)) {
        this.setState({
          extendId: id
        });
      } 
      Toast.loading('请求中...', 0);
      const [err, res] = await to(getNews(id, { limit: 100 }));
      Toast.hide();
      if (err) {
        console.log(err);
        return;
      }
      if(res.success && Array.isArray(res.ch_msg)) {
        this.state.extendIdsMap.set(id, res.ch_msg);
        this.setState({
          extendIdsMap: this.state.extendIdsMap,
          extendId: id
        });
      }
    }
  }

  // 返回
  onBack = () => {
    window.history.back();
  }

  // 到在线客服
  goCustomer = () => {
    if (isNative) {
      nativeHref('customer');
    } else {
      const a = document.createElement('a');
      a.href = 'https://btcccfd.kf5.com/kchat/38726?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0#/chatbox';
      a.target = '_blank';
      a.click();
    }
  }

  // 跳转指定地址
  goHref = (link) => {
    const { history } = this.props;
    history.push(link);
  }
  render() {
    const { loading, askListData } = this.state;

    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <section className={styles.wrapper}>
            {
              isNative ? null :
                <header className={styles.header}>
                  <div className={styles.back} onClick={this.onBack} />
                  <div className={styles.title}>帮助中心</div>
                </header>
            }
            {
              <div className={styles.scroll_container}>
                {
                  askListData.map((item, index) => (
                    <div key={index}>
                        <div className={styles.item_header} onClick={() => this.onExtend(item.id)}>
                          <div className={styles.item_header_title}>{item.name}</div>
                          <div className={
                            `${styles.item_header_arrow} ${this.state.extendId === item.id ? "down" : ''}`
                          }/>
                        </div>
                        <div className={styles.item_content}>
                          {
                            this.state.extendId === item.id && this.state.extendIdsMap.get(item.id) && this.state.extendIdsMap.get(item.id).map((child, index) => (
                              <div key={index} className={styles.item_content_child} onClick={() => this.goHref(`/detail_new/${child.id}`)}>
                                <div className={styles.item_content_child_title}>{child.title}</div>
                                <div className={styles.item_content_child_arrow}/>
                              </div>
                            ))
                          }
                        </div>
                    </div>
                  ))
                }
              </div>
            }
          </section>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
